@import "src/shared/variables";

.root {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 211px;
}

.title {
  font-size: 40px;
  line-height: 44px;
}

.text {
  @include normalArialFont(18px);
  margin-bottom: 56px;
  width: 597px;
}

@import 'src/shared/variables';

.root {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.errorTitle {
  @include normalArialFont(32);
  font-weight: 800;
  margin-top: 40px;
  margin-bottom: 5px;
}

.errorCode {
  @include normalArialFont(16);
  color: $silver_10;
  margin-bottom: 20px;
}

.errorText {
  @include normalArialFont(16);
  color: $silver_0;
  margin-bottom: 30px;
}

/* colors */
$silver-0: #131e24;
$silver-04: #252e33;
$silver-06: #2b353b;
$silver-10: #6b7378;
$silver-30: #97a1a7;
$silver-50: #becad1;
$silver-70: #d3dde3;
$silver-80: #e3eaee;
$silver-90: #edf2f5;
$silver-100: #ffffff;

$blue-04: #006099;
$blue-06: #006fb0;
$blue-08: #0076ba;
$blue-10: #0080cc;
$blue-50: #b2d8ef;
$blue-80: #e1eff7;
$blue-90: #f2faff;

$green-10: #03b890;
$red-10: #f62434;
$orange-10: #f7b21b;

$mf_silver-0: #212621;
$mf_silver-04: #232e23;
$mf_silver-06: #293629;
$mf_silver-08: #324032;
$mf_silver-10: #6d736d;
$mf_silver-30: #99a399;
$mf_silver-50: #c2ccc2;
$mf_silver-60: #ced6ce;
$mf_silver-70: #d5ded5;
$mf_silver-80: #e8edeb;
$mf_silver-90: #f2f5f3;
$mf_silver-95: #f7f9f7;

$mf_green-04: #008a40;
$mf_green-06: #00a14b;
$mf_green-08: #00ab50;
$mf_green-10: #00b956;
$mf_green-50: #abdec3;
$mf_green-80: #cbf2de;
$mf_green-90: #edfcf4;

$mf_purple-10: #731982;
$mf_purple-90: #f5eff6;

$mf_orange-04: #b87811;
$mf_orange-06: #e3961b;
$mf_orange-08: #eda024;
$mf_orange-10: #ffa717;
$mf_orange-50: #fde6c0;
$mf_orange-80: #ffeed1;

$mf_red-06: #b50b19;
$mf_red-08: #c2101e;
$mf_red-10: #f62434;
$mf_red-50: #ffbfc5;
$mf_red-80: #ffe6e7;

$blue: $blue-04;
$white: white;

/* margins */
$leftInfoMargin: 95px;
$header_height: 70px;

/* fonts */
$LatoWebHeavy: 'LatoWebHeavy';
$LatoWebBlack: 'LatoWebBlack';
$MegaFonGraphikLC: 'MegaFon Graphik LC Web';

/* sidebar */
$sidebar-max-width: 220px;
$sidebar-min-width: 180px;

/* gutter */
$gutter: 30px;
$gutter-medium: 45px;
$gutter-large: 75px;

/* popper */
$popper-size: unset;
$popper-arrow-size: 6px;

/* mixins */
@mixin normalArialFont($size) {
    font-size: $size;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
}

@mixin simpleBlueBtn($w, $h, $fontSize) {
    width: $w;
    height: $h;
    border-radius: 2px;
    background-color: $blue;
    color: $white;
    border: none;
    cursor: pointer;
    @include normalArialFont($fontSize);
}

@mixin centerInline($m) {
    display: block;
    margin: $m auto;
}

@mixin disableButton() {
    color: #ccc;
    cursor: default;
}

/* animations */
@keyframes loaded {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes loading {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes onBlur {
    0% {
        filter: blur(0);
    }
    50% {
        filter: blur(1px);
    }
    100% {
        filter: blur(2px);
    }
}

.text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
}

@import "src/shared/variables";

.root {
  position: fixed;
  pointer-events: none;
  z-index: 9980;
  right: 10px;
  top: 10px;
}

.enter {
  transform: translateX(200%);
}

.enter-active {
  transform: translateX(0);
  transition: 0.4s all cubic-bezier(0, 1, 0, 1);
}

.leave {
  transform: translateX(0);
}

.leave-active {
  transform: translateX(200%);
  transition: 1s all cubic-bezier(0, 1, 0.3, 1);
}

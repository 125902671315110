@use "src/shared/variables" as vars;

$darkenDelta: 2.5%;
$saturateDelta: 50%;

.wrapper {
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin itemStyles($color) {
  background-color: $color;
  border-bottom: 1px solid darken($color, $darkenDelta);
}

.item {
  width: 100%;
  padding: 8px 12px;
  text-align: center;
  font-size: 16px;

  &:global(.error) {
    @include itemStyles(vars.$mf_red-50);
  }

  &:global(.warning) {
    @include itemStyles(vars.$mf_orange-50);
  }

  &:global(.info) {
    @include itemStyles(vars.$mf_green-50);
  }

  &:last-child {
    border-bottom: none;
  }
}

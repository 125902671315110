/* global */
:root {
  --notificationsHeight: 0px;
}

* {
  font-family: "LatoWeb", Arial, sans-serif;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  outline: none;
}

html, body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
}

a {
  text-decoration: none;
}

.title {
  font-family: "MegaFon Graphik LC Web", Arial, sans-serif;
  font-weight: 800;
}

.title .table-count{
  font-family: "MegaFon Graphik LC Web", Arial, sans-serif;
}

/*
	This font software is the property of Commercial Type.

	You may not modify the font software, use it on another website, or install it on a computer.

	License information is available at http://commercialtype.com/eula
	For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

	Copyright (C) 2016 Schwartzco Inc.
	
*/


.MegaFonGraphikLC-Light-Web {
    font-family: "MegaFon Graphik LC Web";
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
}

.MegaFonGraphikLC-Regular-Web {
    font-family: "MegaFon Graphik LC Web";
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

.MegaFonGraphikLC-Medium-Web {
    font-family: "MegaFon Graphik LC Web";
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

.MegaFonGraphikLC-Semibold-Web {
    font-family: "MegaFon Graphik LC Web";
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
}

.MegaFonGraphikLC-Bold-Web {
    font-family: "MegaFon Graphik LC Web";
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}

.MegaFonGraphikLC-Black-Web {
    font-family: "MegaFon Graphik LC Web";
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
	font-family: "MegaFon Graphik LC Web";
	src: url("MegaFonGraphikLC-Light-Web.eot");
		src: url("MegaFonGraphikLC-Light-Web.eot?#iefix") format("embedded-opentype"),
		     url("MegaFonGraphikLC-Light-Web.woff2") format("woff2"),
		     url("MegaFonGraphikLC-Light-Web.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	}

@font-face {
	font-family: "MegaFon Graphik LC Web";
	src: url("MegaFonGraphikLC-Regular-Web.eot");
		src: url("MegaFonGraphikLC-Regular-Web.eot?#iefix") format("embedded-opentype"),
		     url("MegaFonGraphikLC-Regular-Web.woff2") format("woff2"),
		     url("MegaFonGraphikLC-Regular-Web.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	}

@font-face {
	font-family: "MegaFon Graphik LC Web";
	src: url("MegaFonGraphikLC-Medium-Web.eot");
		src: url("MegaFonGraphikLC-Medium-Web.eot?#iefix") format("embedded-opentype"),
		     url("MegaFonGraphikLC-Medium-Web.woff2") format("woff2"),
		     url("MegaFonGraphikLC-Medium-Web.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	}

@font-face {
	font-family: "MegaFon Graphik LC Web";
	src: url("MegaFonGraphikLC-Semibold-Web.eot");
		src: url("MegaFonGraphikLC-Semibold-Web.eot?#iefix") format("embedded-opentype"),
		     url("MegaFonGraphikLC-Semibold-Web.woff2") format("woff2"),
		     url("MegaFonGraphikLC-Semibold-Web.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	}

@font-face {
	font-family: "MegaFon Graphik LC Web";
	src: url("MegaFonGraphikLC-Bold-Web.eot");
		src: url("MegaFonGraphikLC-Bold-Web.eot?#iefix") format("embedded-opentype"),
		     url("MegaFonGraphikLC-Bold-Web.woff2") format("woff2"),
		     url("MegaFonGraphikLC-Bold-Web.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	}

@font-face {
	font-family: "MegaFon Graphik LC Web";
	src: url("MegaFonGraphikLC-Black-Web.eot");
		src: url("MegaFonGraphikLC-Black-Web.eot?#iefix") format("embedded-opentype"),
		     url("MegaFonGraphikLC-Black-Web.woff2") format("woff2"),
		     url("MegaFonGraphikLC-Black-Web.woff") format("woff");
	font-weight: 800;
	font-style: normal;
	}